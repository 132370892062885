import React, { Fragment, useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Field, Formik, ErrorMessage } from "formik";
import {
  setLoading,
  clearError,
} from "../../redux/Slice/Message";
import "./styles.css";
import { fecthCategories } from "../../redux/Slice/Categories";
export default function SearchBar(props) {
  const dispatch = useDispatch();
  const { ErrorMsg } = useSelector((state) => state.Message);
  const { Categories ,isSuccess } = useSelector(
    (state) => state.persistedReducer.Categories
  );
  const initialvalues = {
    category: "",
    search: "",
  };
  const validationSchema = Yup.object().shape({
    category: Yup.string(),
    search: Yup.string(),
  });
  const handleSubmitSearch = (values, { resetForm }) => {
    dispatch(setLoading({ Message: "Searching...." }));
    let searchingData = { category: values.category, search: values.search };
    console.log(searchingData);
    resetForm();
    // axios
    //   .post("/user/signin", userLoginData)
    //   .then((res) => {
    //     props.toggle();
    //     dispatch(
    //       LoginUser({
    //         email: res.data.email,
    //         _id: res.data._id,
    //         name: res.data.name,
    //         cellno: res.data.cellno,
    //         token: res.data.token,
    //         isLogin: true,
    //       })
    //     );
    //     localStorage.setItem(config.Token, res.data.token);
    //     toast.success("Login Successfully", {
    //       position: "top-right",
    //       autoClose: 3000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //     });
    //     localStorage.removeItem(config.VerifyEmail);
    //     dispatch(resetLoading());
    //     navigate("/");
    //   })
    //   .catch((err) => {
    //     if (err.response.data.message) {
    //       dispatch(setError({ ErrorMsg: err.response.data.message }));
    //       toast.error(err.response.data.message, {
    //         position: "top-right",
    //         autoClose: 3000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //       });
    //     }
    //     dispatch(resetLoading());
    //     navigate("/");
    //   });
  };
  useEffect(() => {
    if(!isSuccess)
    dispatch(fecthCategories());
  },[isSuccess ]);
  return (
    <Fragment>
      <Formik
        initialValues={initialvalues}
        onSubmit={handleSubmitSearch}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className="container mx-auto m-0 py-3 px-sm-0  px-1" >
            <div className="row p-0 m-0">
              <span style={{ color: "red", padding: "0px", margin: "0px" }}>
                {ErrorMsg}
              </span>
            </div>
            <div className="row p-0 m-0">
              <div className="col-md-3  p-0 m-0 ">
                <Field
                  as="select"
                  name="category"
                  className="form-control   rounded-0  my-md-0 my-1"
                >
                  <option value="" >
                        All Categories
                        </option>
                  {Categories &&
                    Categories.map((category, key) => {
                      return (
                        <option value={category.id} id={key}>
                          {category.CategoriesName}
                        </option>
                      );
                    })}
                </Field>
              </div>
              <div className="col-md-7   p-0 m-0">
                <Field
                  type="text"
                  name="search"
                  onFocus={() => dispatch(clearError())}
                  className="form-control   rounded-0 my-md-0 my-1"
                  placeholder="Search"
                />
              </div>
              <div className="col-md-2  p-0 m-0">
                <button
                  type="submit"
                  className="btn btn-secondary w-100 rounded-0  border  border-secondary border-1 m-0 form-control my-md-0 my-1"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="row p-0 m-0">
              <ErrorMessage
                style={{ color: "red" }}
                component="div"
                name="category"
              />{" "}
              <ErrorMessage
                style={{ color: "red" }}
                component="div"
                name="search"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}
