import { createSlice } from '@reduxjs/toolkit'
export const auth = createSlice({
  name: 'auth',
  initialState: {
    ResetPasswordToggle:false,
    SignInUpToggle:true,
  },
  reducers: {
    setSignInUpToggle: (state, action) => {
      state.SignInUpToggle = action.payload;
    },
    setResetPasswordToggle: (state, action) => {
      state.ResetPasswordToggle = action.payload;
    },
   
  },
})

export const { setSignInUpToggle,setResetPasswordToggle } = auth.actions
export default auth.reducer
