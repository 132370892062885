import { createSlice } from "@reduxjs/toolkit";
export const Message = createSlice({
  name: "Message",
  initialState: {
    isLoading: false,
    Message: "",
    ErrorMsg: "",
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = true;
      state.Message = action.payload.Message;
    },
    resetLoading: (state, action) => {
      state.isLoading = false;
      state.Message = "";
    },
    setError: (state, action) => {
      state.ErrorMsg = action.payload.ErrorMsg;
    },
    clearError: (state, action) => {
      state.ErrorMsg = "";
    },
  },
});

export const {
  setLoading,
  resetLoading,
  setError,
  clearError,
} = Message.actions;
export default Message.reducer;
