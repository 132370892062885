import { createSlice } from '@reduxjs/toolkit'
export const User = createSlice({
  name: 'User',
  initialState: {
    email: "",
    _id: '',
    name:'',
    cellno:'',
    token:'',
    isLogin:false
  },
  reducers: {
    LoginUser: (state, action) => {
      state.email = action.payload.email;
      state._id = action.payload._id;
      state.name = action.payload.name;
      state.cellno = action.payload.cellno;
      state.token = action.payload.token;
      state.isLogin=true;
    },
    LogoutUser:(state, action) => {
      state.email = '';
      state._id = '';
      state.name = '';
      state.cellno = '';
      state.token = '';
      state.isLogin=false;
    }

  },
})

export const { LoginUser, LogoutUser } = User.actions
export default User.reducer
