import pizza1 from "../images/pizza.jpg";
import pizza2 from "../images/pizza.jpg";
import pizza3 from "../images/pizza.jpg";
import pizza4 from "../images/pizza.jpg";
import pizza5 from "../images/pizza.jpg";
import pizza6 from "../images/pizza.jpg";
import pizza7 from "../images/pizza.jpg";
import pizza8 from "../images/pizza.jpg";

export const Brands = [
  {
    id: 1,
    src: pizza1
  },
  {
    id: 2,
    src: pizza2
  },
  {
    id: 3,
    src: pizza3
  },
  {
    id: 4,
    src: pizza4
  },
  {
    id: 5,
    src: pizza5
  },
  {
    id: 6,
    src: pizza6
  },
  {
    id: 7,
    src: pizza7
  },
  {
    id: 8,
    src: pizza8
  }
];
