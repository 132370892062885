import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { ButtonGroup, Container } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import ReactStars from "react-rating-stars-component";
import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addToCart, removeToCart } from "../redux/Slice/Cart-Store";
import { useParams } from "react-router-dom";
import axios from "axios";
export default function SingleProduct() {
  document.title="Product"
  let { id } = useParams();
  console.log(id);
  const dispatch = useDispatch();
  const [currentProduct, setcurrentProduct] = useState([]);
  useEffect(() => {
    axios
      .get(`p/${id}`)
      .then((res) => {
        if (res.statusText === "OK") {
          setcurrentProduct(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setcurrentProduct([]);
      });
  }, []);

  const addproduct = () => {
    dispatch(addToCart(currentProduct));
  };
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  const priceOld = {
    textDecorationLine: "line-through",
    color: "#afaaaa",
  };

  console.log(currentProduct, "curr");
  return (
    <Container className="my-3">
      <Card className="border-0 position-relative flex-row">
        <img
          src={"../assets/img/products/special_products/" + currentProduct.Src}
          height="500px"
          width="400px"
          alt=""
        />

        <Card.Body className="w-50 text-left mt-5">
          <Card.Title>{currentProduct.Name}</Card.Title>

          <ReactStars
            count={5}
            size={24}
            color="gray"
            a11y={true}
            edit={true}
            isHalf="true"
            value={currentProduct.Rating}
            onChange={ratingChanged}
            activeColor="#ffd700"
            className=""
          />
          <hr></hr>
          <Card.Text className="price-new mb-0">
            {currentProduct.Price}#
          </Card.Text>
          <Card.Text className="price-old" style={priceOld}>
            {!currentProduct.OldPrice ? "" : currentProduct.OldPrice}
          </Card.Text>
          <hr></hr>
          <Card.Text>{currentProduct.Description}</Card.Text>
          <ButtonGroup className="mt-5 btnGroup d-flex justify-content-center mb-3 position-absolute">
            <Button
              variant="dark"
              className="wish me-3"
              size="sm"
              id={currentProduct._id}
              name={currentProduct.Name}
              onClick={(e) => {}}
              disabled={false}
            >
              <Icon.Heart color={true === false? "red" : "white"}></Icon.Heart>
            </Button>
            <Button
              variant="dark"
              size="sm"
              onClick={() => {
                addproduct();
              }}
            >
              Add To Cart
            </Button>
          </ButtonGroup>
        </Card.Body>
      </Card>
    </Container>

  );
}
