import React from 'react'
import { useSelector } from 'react-redux';

const Loading = () => {
  document.title='Loading...'
  const {isLoading,Message} = useSelector((state) => state.Message);
  return (isLoading?
    <section className=' position-absolute p-0 m-0 h-100 w-100 '  style={{
      zIndex: 999,
      top: "0",
      left: "0",
      backgroundColor:'rgba(255, 255, 255, 0.8)'
    }}>
    <div
    className="spinner-border position-fixed"
    style={{
      zIndex: 999,
      width: "3rem",
      height: "3rem",
      top: "calc(50% - 1.5rem)",
      left: "calc(50% - 1.5rem)",
      backgroundColor:'rgba(255, 255, 255, 0.8)'
    }}
    role="status"
  >
  </div>
  <div className='  position-fixed text-center w-100'
  style={{
    zIndex: 999,
    top: "calc(50% + 3rem)",
    left: "0",
  }}>{Message?Message+'....':'Loading .....'}</div>
  </section>:'')
  
}

export default Loading
