import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import TopHeader from "./TopHeader";

export const Layout = () => {
  return (
    <Fragment>
      <TopHeader />
      <NavBar />
      <Outlet />
      <Footer />
    </Fragment>
  );
};
