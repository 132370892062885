import { Col, Container, Row } from "react-bootstrap";
import { EnvelopeFill, HeartFill, TelephoneFill ,Cart4,PersonCircle} from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import React from "react";

export default function TopHeader() {
  const { totalQuantityCart, totalQuantityWish, isChange,Cart,Wish ,toggle } = useSelector(
    (state) => state.persistedReducer.CartStore
  );
  const {islogin,name} = useSelector((state) => state.persistedReducer.User);
  return (
    <section className=" bg-black text-white fixed-bottom ">
      <Container>
        <Row>
          <Col lg={4} sm={12} className="text-lg-start text-center py-1 d-lg-block d-none">
            <EnvelopeFill className="me-2"></EnvelopeFill>
            munirahmad.iub@gmail.com
        
            
          </Col>
          <Col lg={8} sm={12} className=" py-1 px-0 row">
            <div className="col-4  my-auto">    
            <Link className=" FooterLink text-white d-flex flex-row align-items-center justify-content-center" to="/wishList">
              <HeartFill className="me-2"></HeartFill>
              <span className="d-sm-block d-none">Wish List</span>
              <span className="ms-1 ">({totalQuantityWish})</span>
            </Link>
            </div>
            <div className="col-4 my-auto">
            <Link className=" FooterLink text-white d-flex flex-row align-items-center justify-content-center" to="/shoppingCart">
              <Cart4 className="me-2"></Cart4>
              <span className="d-sm-block d-none">Shopping Cart</span>
              <span className="ms-1">({totalQuantityCart})</span>
            </Link>
            </div>
            <div className="col-4  my-auto">
            <Link className=" FooterLink text-white d-flex flex-row align-items-center justify-content-center " to="/">
              <PersonCircle className="me-2 "></PersonCircle>
            
              <span className="d-sm-block d-none text-white">{!islogin?name:''}</span>
              
            </Link>
            </div>
        
          </Col>
        </Row>
      </Container>
    </section>
  );
}
