import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
export const Categories = createSlice({
  name: "Categories",
  initialState: {
    Categories: [],
    ErrorMsg: "",
    isSuccess :false,
  },
  reducers: {
    getCategories: (state, action) => {
      state.Categories = action.payload.Categories;
      state.ErrorMsg = "";
    state.isSuccess =true;
    },
    setError: (state, action) => {
      state.ErrorMsg = action.payload.ErrorMsg;
      state.Categories = [];
    },
  },
});

export const { getCategories, setError } = Categories.actions;
export default Categories.reducer;
export const fecthCategories = () => {
  return (dispatch) => {
    axios
      .get("/categories")
      .then((res) => {
        dispatch(getCategories({ Categories: res.data }));
      })
      .catch((err) => {
        if (err.response.data.message) {
          dispatch(setError({ ErrorMsg: err.response.data.message }));
        }
      });
  };
};
