import React, { Fragment } from "react";
import MainSlider from "../components/MainSlider/index";
import Types from "../components/classifications";
import ProductTabs from "../components/productSliderTabs";
import Brandslogo from "../components/brandsLogo/index";
import ProductCategoryWise from "../components/Categories";
import Header from "./Home/Header";
import Footer from "./Home/Footer";
import Categories from "../components/Categories";
import SearchBar from "./Home/SearchBar";
export default function Home() {
  document.title = "Home";
  return (
    <Fragment>
      <Header />
      <SearchBar />
      <Categories />
    </Fragment>
  );
}
