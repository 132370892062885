import "./style.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import WishListPage from "./pages/wishList";
import CartListPage from "./pages/ShowCartList";
import SingleProduct from "./pages/SingleProduct";
import Home from "./pages/Home";
import React, { useState } from "react";
import Shop from "./pages/Shop";
import { Layout } from "./pages/Home/Layout";
import Error from "./pages/Error";
function App() {
  
  return (
    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />}/>
          <Route path="shop" element={<Shop/>}/>
          <Route path="shop/:id" element={<SingleProduct/>}/>
          <Route path="wishList" element={<WishListPage />}></Route>
          <Route path="shoppingCart" element={<CartListPage  />}/>
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
