import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
const Footer = () => {
  return (

      <Container fluid className="p-0 m-0 mb-5">

        <div
          className="row p-0 m-0 py-3 bg-dark text-white"
          style={{}}
        >
          <div className="col-md-6 text-md-start  text-center">
            <i className="fa-solid fa-copyright"></i>&nbsp;2022 Copyright:
            <strong  className=" text-uppercase">&nbsp; Tara Botanical Service</strong>
          </div>
          <div className="col-md-6 ">
            <ul className="navbar-nav d-flex  flex-row justify-content-md-end  justify-content-center p-0 m-0">
              <li className="nav-item p-0">
                <span className="nav-link  p-0 pe-1 text-white">
                  Terms of Use
                </span>
              </li>
              <li className="nav-item p-0">
                <span className="nav-link  p-0 pe-1 text-white">
                  | Privacy Policy
                </span>
              </li>
              <li className="nav-item p-0">
                <span className="nav-link p-0 text-white">| Cookie Policy</span>
              </li>
            </ul>
          </div>
</div>
      </Container>

  )
}

export default Footer
