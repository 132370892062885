import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import React, { Fragment } from "react";
import AuthUser from '../../components/Auth/AuthUser';
import config from '../../config';
import { useParams } from 'react-router-dom';


const NavBar = () => {
  const {email}=useParams();
  if(email&&email===localStorage.getItem(config.VerifyEmail))
  {localStorage.removeItem(config.VerifyEmail)}
  return (
    <Fragment>   {localStorage.getItem(config.VerifyEmail)&&<div
      className="w-100 p-0 m-0  position-absolute "
       style={{ top:"20%"}}
      
    >
      <div role="alert" className=' alert  alert-danger  text-center fw-bold w-75 mx-auto'>  Please verify your email address <strong className=" text-decoration-underline" style={{color:"#235823"}}>{localStorage.getItem(config.VerifyEmail)}</strong> before Login.
    </div>
     
    </div>}
    
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="sticky-top">
     
     <Container className="">
       <Navbar.Brand href="#home" > <h3 className=' text-uppercase d-lg-block d-none p-0 m-0'><span className=' text-info'>T</span>ara <span className=' text-success'>B</span>otanical <span className=' text-danger'>S</span>ervice</h3>
       <h3 className=' text-uppercase d-lg-none d-block p-0 m-0 text-danger'><span className=' text-info'>T</span><span className=' text-success'>B</span>S</h3></Navbar.Brand>
       <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       <Navbar.Collapse id="responsive-navbar-nav">
         <Nav className="me-auto">
         <Nav.Link href="/">Home</Nav.Link>
         <Nav.Link href="/Shop">Shop</Nav.Link>
           <NavDropdown title="All Categories" id="collasible-nav-dropdown">
             <NavDropdown.Item href="#">Grains and Seeds</NavDropdown.Item>
             <NavDropdown.Item href="#">Powder and Capsules</NavDropdown.Item>
             <NavDropdown.Item href="#">Oil and Serum</NavDropdown.Item>
             <NavDropdown.Item href="#">Spices and Herbs</NavDropdown.Item>
             <NavDropdown.Item href="#">Herbal and Organic Teas</NavDropdown.Item>
             <NavDropdown.Item href="#">Herbal and Organic Teas</NavDropdown.Item>
             <NavDropdown.Divider />
             <NavDropdown.Item href="#">
             Bundle Offers
             </NavDropdown.Item>
           </NavDropdown>
         </Nav>
         <Nav>
           {/* <Nav.Link href="#deets">Create Account</Nav.Link>
           <Button variant="primary" style={{width:'100px'}}>Login</Button> */}
           <form className="d-flex">
         <AuthUser />
         </form>
         </Nav>
       </Navbar.Collapse>
     </Container>
   </Navbar>
    
    </Fragment>
   
  );
};

export default NavBar;
