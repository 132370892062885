import React,{useState,useEffect} from "react";
import Product from "../Product";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "./style.css";
import axios from "axios";
export default function Categories() {
  const CategoryName = "Latest products";
  const [CategoryItems,setCategoryItems] =useState([])
 useEffect(() => {
    axios
      .get(`p/`)
      .then((res) => {
        if (res.statusText === "OK") {
          setCategoryItems(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setCategoryItems([]);
      });
 
  }, []);

  const ProductsResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1500 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 775 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 775, min: 0 },
      items: 1,
    },
  };
  return (
    <Container className="p-0 py-3">
      <h5 className="text-center my-4 text-uppercase">{CategoryName}</h5>
      <Carousel
        controls="false"
        responsive={ProductsResponsive}
        infinite={true}
        autoPlay={true}
        className="p-0 m-0 position-relative"
      >
        {CategoryItems.map((Items,i) => (
          <Product key={i} product={Items}/>
        ))}
      </Carousel>
    </Container>
  );
}
