import "./style.css";
import { Container, Table, Row, Button, ButtonGroup } from "react-bootstrap";
import { PlusCircleFill, DashCircleFill } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { addToCart, removeToCart } from "../redux/Slice/Cart-Store";
export default function ShowCartList() {
  document.title = "Cart List";
  const Cartitems = useSelector(
    (state) => state.persistedReducer.CartStore.Cart
  );
  const { toggle } = useSelector((state) => state.persistedReducer.CartStore);
  const dispatch = useDispatch();
  const [subTotalPrice, setsubTotalPrice] = useState(0);
  const [tax, setTax] = useState(2);
  const [totalPrice, setTotalPrice] = useState(0);
  const [update, setupdate] = useState(false); //this state use for one time Render UseEffect Function
  useEffect(() => {
    console.log("ooo", Cartitems);
    if (!update) {
      let price = 0;
      Cartitems.forEach((item) => {
        price += item.totalPrice;
      });
      setsubTotalPrice(price);
      setTax(Math.round((tax / 100) * price));
      setTotalPrice(price !== 0 ? price + Math.round((tax / 100) * price) : 0);
      setupdate(true);
    }
  }, [update, toggle]);
  console.log(Cartitems);
  return (
    <Fragment>
      <div className="mb-5 mt-5">
        <Container>
          <h5 className="text-left mb-4 ps-2">Cart List</h5>
          <Row>
            <div
              className=" col-lg-9"
              style={{
                overflowX: "scroll",
                scrollbarWidth: "thin",
              }}
            >
              <div className="p-0 m-0" style={{ minWidth: "800px" }}>
                <div className="row ">
                  <div className="col-2 text-center border border-1 py-2 bg-light">
                    Product Img
                  </div>
                  <div className="col-2 text-center border border-1  py-2 bg-light">
                    Name
                  </div>
                  <div className="col-2 text-center border border-1 py-2 bg-light">
                    Price
                  </div>
                  <div className="col-2 text-center border border-1 py-2 bg-light">
                    Quantity
                  </div>
                  <div className="col-2 text-center border border-1 py-2 bg-light">
                    Sub Total
                  </div>
                  <div className="col-2 text-center border border-1 py-2 bg-light">
                    Action
                  </div>
                </div>
                {Cartitems.map((item, i) => {
                  return (
                    <div className="row py-1" key={i}>
                      <div className="col-2 text-center border border-1">
                        <Link to={`/product/${item.id}`}>
                          <img
                            src={
                              "./assets/img/products/special_products/" +
                              item.src
                            }
                            alt="productImg"
                            className="w-100"
                            onClick={() => {}}
                          />
                        </Link>
                      </div>
                      <div
                        className="col-2 text-center border border-1 d-flex align-items-center"
                        onClick={() => {}}
                      >
                        <Link
                          className="text-center"
                          to={`/product/${item.id}`}
                        >
                          {item.name}
                        </Link>
                      </div>
                      <div className="col-2 text-center border border-1 d-flex justify-content-center align-items-center">
                        {item.price}$
                      </div>
                      <div className="col-2 text-center border border-1 d-flex justify-content-center align-items-center">
                        {item.quantity}
                      </div>
                      <div className="col-2 text-center border border-1 d-flex  justify-content-center align-items-center">
                        {item.totalPrice}$
                      </div>
                      <div className=" col-2 text-center border border-1 d-flex justify-content-center  align-items-center">
                        <Button
                          variant="white"
                          style={{}}
                          className="p-0 m-0"
                          onClick={() => {
                            if (item.quantity <= item.maxQuantity) {
                              setsubTotalPrice(0);
                              setTotalPrice(0);
                              setTax(2);
                              setupdate(false);
                              dispatch(addToCart({ _id: item.id }));
                            }
                          }}
                          disabled={
                            item.quantity >= item.maxQuantity + 1 ? true : false
                          }
                        >
                          <PlusCircleFill size={24} />
                        </Button>
                        <Button
                          variant="white"
                          style={{}}
                          className="p-0 m-0 px-1 text-center"
                          disabled
                        >
                          {item.quantity}
                        </Button>
                        <Button
                          variant="white"
                          className="p-0 m-0"
                          style={{}}
                          onClick={() => {
                            if (item.quantity >= 1) {
                              setsubTotalPrice(0);
                              setTotalPrice(0);
                              setTax(2);
                              setupdate(false);
                              dispatch(removeToCart(item.id));
                            }
                          }}
                          disabled={item.quantity < 1 ? true : false}
                        >
                          <DashCircleFill size={24} />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3 m-0  mx-lg-auto  boxShadaw bg-lg-light p-4 ">
              <h5 className="text-left mb-4 pb-2">Cart Price</h5>
              <div className="d-flex justify-content-between mb-4">
                <h6 className="fw-normal">SubTotal Price :</h6>
                <span>{subTotalPrice}$</span>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <h6 className="fw-normal">Tax :</h6>
                <span>{tax}$</span>
              </div>

              <div className="d-flex justify-content-between fw-bold">
                <h6>Total Price :</h6>
                <span>{totalPrice}$</span>
              </div>
              <Button variant="dark" size="md" className="mt-4 w-100">
                Pay Now
              </Button>
            </div>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}
