import React, { Fragment } from "react";
import NavBar from "./NavBar";
import TopHeader from "./TopHeader";
import SearchBar from "./SearchBar";
const Header = () => {
  return (
    <div
      className=" container px-0 py-5 m-0  mx-auto d-flex flex-column justify-content-start align-content-center h-100 bg-dark"
      style={{
        // backgroundImage: "url('./assets/img/front-page/cover.jpg')",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
        // backgroundSize: "cover",
      }}
    >
    
      <div
        className="text-white text-center my-auto"
        style={{ fontWeight: 900 }}
      >
        {" "}
        <h1 className="mb-5">
          Welcome to{" "}
          <strong className="" style={{ color: "rgb(93 243 35)" }}>
            Tara Botanical Service
          </strong>
        </h1>
        <h4>The best way to predict the future is to create it</h4>
        <p className="lead">Change is the end result of all true learning</p>
        <div className="lead">
          <div class="col">
            <button type="button" name="" id="" class="btn  btn-light">Get Start</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
