import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import ReactStars from "react-rating-stars-component";
import { useDispatch } from "react-redux";
import { addToCart, removeToCart } from "../../redux/Slice/Cart-Store";
import "./style.css";
export default function product(props) {
  const dispatch = useDispatch();
  const productItem = props.product;
  const [wish, setWish] = useState(0);

  const clickWish = (props) => {};

  const addproductItem = () => {
    dispatch(addToCart(productItem));
  };
  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  return (
    <Card
      style={{ width: "20rem" }}
      className="p-0 m-0 mx-auto  bg-light d-flex justify-content-center text-center  position-relative Card"
    >
      <Card.Img variant="top" className="p-2" src={"./assets/img/products/special_products/"+productItem.Src} />
      <Card.Body className="Cardbody">
        <Card.Title className=" text-capitalize">{productItem.Name}</Card.Title>
        <div className=" d-flex  flex-column justify-content-center align-items-center">
          <div className="item-price d-flex justify-content-center text-center align-items-center">
            <strike className="me-2">${productItem.oldPrice}</strike>{" "}
            <b>${productItem.Price}</b>
          </div>
          <ReactStars
            count={5}
            size={30}
            color="gray"
            a11y={true}
            edit={true}
            isHalf="true"
            value={productItem.Rating}
            onChange={ratingChanged}
            activeColor="#ffd700"
            className=""
          />
        </div>
        <div className="row py-3">
          <ButtonGroup className="" style={{}} aria-label="Basic example">
            <Link to={""}>
              <Button variant="dark" onClick={() => clickWish(productItem)}>
                <Icon.Heart color={"white"}></Icon.Heart>
              </Button>
            </Link>
            <Button
              variant="dark"
              style={{ width: "120px" }}
              onClick={() => {
                addproductItem();
              }}
            >
              Add to Cart
            </Button>
            <Link to={"/Shop/" + productItem._id}>
              <Button variant="dark">
                <Icon.Eye></Icon.Eye>
              </Button>
            </Link>
          </ButtonGroup>
        </div>
      </Card.Body>
    </Card>
  );
}
