import { createSlice } from "@reduxjs/toolkit";
export const CartStore = createSlice({
  name: "CartStore",
  initialState: {
    Cart: [],
    totalQuantityCart: 0,
    showCart: false,
    Wish: [],
    totalQuantityWish: 0,
    showWish: false,
    isChange:false,  // For Data base synchronization
    toggle:false  //data change toggle
  },
  reducers: {
    addAllDataOfCartStore: (state, action) => {
      state.Cart=action.payload.Cart;
      state.totalQuantityCart=action.payload.totalQuantityCart;
      state.Wish=action.payload.Wish;
      state.totalQuantityWish=action.payload.totalQuantityWish;
      state.showCart=false;
      state.showWish=false
      state.isChange=false;
      state.toggle=false;
     },
    addToCart: (state, action) => {
      const newitem = action.payload;
      const existingItem = state.Cart.find((item) => item.id === newitem._id);
      if (existingItem) {
        existingItem.quantity++;
        existingItem.totalPrice += existingItem.price;
      } else {
        state.totalQuantityCart++;
        state.Cart.push({
          id: newitem._id,
          price: newitem.Price,
          src: newitem.Src,
          quantity: 1,
          maxQuantity: newitem.MaxQuantity,
          totalPrice: newitem.Price,
          name: newitem.Name,
        });
      }
      state.toggle=!state.toggle;
      state.isChange=true;
    },
  
    removeToCart: (state, action) => {
      const id = action.payload;
      const existingItem = state.Cart.find((item) => item.id === id);
      if (existingItem.quantity === 1) {
        state.Cart = state.Cart.filter((item) => item.id !== id);
        state.totalQuantityCart--;
      } else {
        existingItem.quantity--;
        existingItem.totalPrice -= existingItem.price;
      }
      state.toggle=!state.toggle;
      state.isChange=true;
    },

    addToWish: (state, action) => {
      state.Wish.push(action.payload);
    },
    removeToWish: (state, action) => {
      return state.Wish.filter((cart) => cart.id !== action.payload);
    },
  }
});

export const {
  addAllDataOfCartStore,
  addToCart,
  removeToCart,
  addToWish,
  removeToWish,
} = CartStore.actions;
export default CartStore.reducer;
