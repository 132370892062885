import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import axios from 'axios';
import config from './config';
axios.defaults.baseURL=config.ServerURL;
let persistor = persistStore(store);
const rootElement = ReactDOM.createRoot(document.getElementById('root'));
rootElement.render( 
    <Provider store={store}>
        <PersistGate persistor={persistor}>
        <App />
        </PersistGate>
    </Provider> 
);
reportWebVitals();


